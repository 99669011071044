const Form = {  
    sendData: (form) => {
        const xhr = new XMLHttpRequest();
        const formData = new FormData(form);
        const formId = form.id;

        form.querySelector('button')?.classList.add('is-sending');

        xhr.addEventListener( "load", function(event) {
            form.reset();

            const elements = form.elements;

            for (var i = 0; i < elements.length; i++) {
                elements[i].classList.remove('has-value')
            }

            form.querySelector('button')?.classList.remove('is-sending');
            form.querySelector('button')?.classList.add('is-completed');
            setTimeout(function() {
              form.querySelector('button')?.classList.remove('is-completed');
            }, 5550);
        });

        xhr.addEventListener( "error", function( event ) {
            alert( 'Oops! Something went wrong. Please try again.' );
          } );


        xhr.open( "POST", settings.ajaxurl );

        formData.append('action', formId);
        xhr.send( formData );
    }
}


export default Form;