import { html, body } from "./utils/environment";
import { fontsLoader } from "./utils/fonts";
import { preloadImages } from "./utils/image";
import modular from "modujs";
import * as modules from "./modules";
import globals from "./globals";

const fonts = [
    { name: "Sunroll", style: "normal", weight: "normal" },
    { name: "Sunroll", style: "normal", weight: 700 },
    { name: "Point Black", style: "normal", weight: 900 },
    { name: "Point", style: "italic", weight: 400 },
];

const app = new modular({
    modules,
});

const onFontsLoaded = () => {
    html.classList.add("is-fonts-loaded");
};

const onImagesPreloaded = () => {
    html.classList.add("is-images-preloaded");
};

const onAllAssetsLoaded = () => {
    globals();
    app.init(app);

    setTimeout(() => {
        html.classList.remove("has-no-js");
        html.classList.remove("is-loading");

        html.classList.add("is-first-loaded");
        html.classList.add("is-loaded");

        setTimeout(() => {
            html.classList.add("is-init");
            html.classList.add("is-ready");

            // Check for anchor in URL and scroll to it
            const hash = window.location.hash;
            if (hash) {
                const targetElement = document.querySelector(hash);
                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        })
    }, 200);
};

function init() {
    window.isFontsLoaded = false;

    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
        document.body.classList.add('u-debug-screens');
    }

    let fontsPromise = new Promise((resolve, reject) => {
        fontsLoader(fonts, () => {
            onFontsLoaded?.();
            window.isFontsLoaded = true;
            resolve();
        });
    });

    let imagesPromise = new Promise((resolve) => {
        preloadImages(() => {
            onImagesPreloaded?.();
            resolve();
        })
    })

    Promise.all([fontsPromise, imagesPromise]).then(() => {
        onAllAssetsLoaded?.();
    });
}

document.addEventListener('readystatechange', event => {
    if (event.target.readyState === "complete") {
        init();
    }
});
