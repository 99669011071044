import { module } from 'modujs';

export default class Youtube extends module {
    static isModalOpen = false;

    constructor(m) {
        super(m);
        this.events = {
            click: {
                video: 'openVideo'
            }
        }
    }

    setupModal() {
        this.modal = document.createElement('div');
        this.modal.className = 'c-video-modal is-active';
        this.modal.innerHTML = `
            <div class="c-video-modal__content">
                <button class="c-video-modal__close">Fermer</button>
                <div id="player"></div>
            </div>
        `;
        document.body.appendChild(this.modal);

        this.modal.querySelector('.c-video-modal__close').addEventListener('click', () => this.close());
        this.modal.addEventListener('click', (e) => {
            if (e.target === this.modal) this.close();
        });
    }

    openVideo(e) {
        e.preventDefault();
        if (Youtube.isModalOpen) return;

        Youtube.isModalOpen = true;
        const videoUrl = e.currentTarget.getAttribute('data-youtube-url');
        const videoId = videoUrl.split('v=')[1];

        this.setupModal();

        this.player = new YT.Player('player', {
            height: '450',
            width: '100%',
            videoId: videoId,
            playerVars: { 'autoplay': 1 }
        });
    }

    close() {
        if (this.player) this.player.destroy();
        this.modal.remove();
        Youtube.isModalOpen = false;
    }

    destroy() {
        if (this.modal) {
            this.modal.remove();
            Youtube.isModalOpen = false;
        }
    }
}