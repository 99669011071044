import { module } from 'modujs';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import EmblaCarousel from 'embla-carousel'
import { currentBreakpoint } from '../utils/screen';

gsap.registerPlugin(ScrollTrigger);

export default class extends module {
    constructor(m) {
        super(m);

        this.$el = this.el
        this.$elements = this.$('element')
    }

    init() {
        const bp = ['xs', 'sm', 'md'];
        if (!bp.includes(currentBreakpoint)) {
            gsap.to(this.$elements, {
                xPercent: -100 * (this.$elements.length - 1),
                ease: "sine.out",
                scrollTrigger: {
                    trigger: this.$el,
                    snap: 1 / (this.$elements.length - 1),
                    end: "+=" + this.$el.offsetWidth,
                    scrub: 1,
                    // markers: true,
                    pin: true,
                }, 
            });
        } else {
            const options = { loop: false, containScroll: false }
            const emblaApi = EmblaCarousel(this.$el, options)
        }
    }
}
