import { module } from 'modujs';
import { html } from '../utils/environment'
export default class extends module {
    constructor(m) {
        super(m)

        this.$el = this.el
        this.isOpen = false
        this.activeClass = 'has-menu-open'

        this.trigger = this.$('trigger');

        this.$elements = this.$el.querySelectorAll('a[href*="#"]');

        this.clickOutsideBind = this.clickOutside.bind(this)
        this.onClickBind = this.onClick.bind(this)
        this.onNavigateBind = this.onNavigate.bind(this)
    }

    init() {
        this.bindEvents()
        this.generateLink();
    }

    generateLink() {
        this.$elements = this.$el.querySelectorAll('a[href*="#"]');

        this.$elements.forEach( e => {

            const currentUrl = new URL(window.location.href);
            const linkUrl = new URL(e.getAttribute('href'), window.location.origin);
            const linkFragment = linkUrl.hash;

            // // Remove the fragment
            currentUrl.hash = '';
            linkUrl.hash = '';

            if(currentUrl.href === linkUrl.href) {
                console.log('it is same', linkFragment);
                e.setAttribute('href', linkFragment);
            } else {
                e.setAttribute('href', `${linkUrl.href}${linkFragment}`);
            }

            e.addEventListener('click', this.onNavigateBind);
        })
    }

    bindEvents() {
        Array.from(this.trigger).forEach( e => {
            e.addEventListener('click', this.onClickBind);
        })
    }

    onNavigate(e) {
        e.preventDefault();
        const target = e.target.getAttribute('href');

        const linkUrl = new URL(target, window.location.origin);
        const linkFragment = linkUrl.hash;

        this.call('deactivate', null, 'Nav')
        this.call('scrollTo', linkFragment, 'Scroll')
    }

    onClick() {
        this.toggle()
    }

    activate() {
        this.isOpen = true
        html.classList.add(this.activeClass);

        setTimeout( () => {
            document.addEventListener('click', this.clickOutsideBind);
        }, 200)
    }

    deactivate() {
        this.isOpen = false

        html.classList.remove(this.activeClass);
        this.unbindEvents();
    }

    toggle() {
        if (html.classList.contains(this.activeClass)) {
            this.deactivate();
        } else {

            this.activate();
        }
    }

    open() {
        if (this.isOpen) return
        this.activate()
        this.isOpen = true
    }

    close() {
        if (!this.isOpen) return
        this.deactivate()
        this.isOpen = false
    }

    unbindEvents() {
        document.removeEventListener('click', this.clickOutsideBind);
    }

    clickOutside(event) {
        if (!this.$el.contains(event.target) && this.isOpen) {
            this.close();
        }
    }

}