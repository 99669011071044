import { module } from 'modujs'
import modularLoad from 'modularload'
import { preloadImages } from "../utils/image";

export default class extends module {
    constructor(m) {
        super(m)
    }

    onImagesPreloaded(oldContainer, newContainer) {

        console.log('on image preloaded');
        this.call('scrollTo', 0, 'Scroll');

        this.call('destroy', oldContainer, 'app')
        this.call('update', newContainer, 'app')
        this.call('generateLink', null, 'Nav');

        const linkFragment = window.location.hash;

        if(linkFragment) {
            this.call('scrollTo', linkFragment, 'Scroll')
        }
    }

    init() {
        this.load = new modularLoad({
            enterDelay: 800,
        });

        this.load.on('loading', (transition, oldContainer) => {
        });

        this.load.on('loaded', (transition, oldContainer, newContainer) => {
            const self = this;

            console.log('loaded');

            new Promise( (resolve) => {
                preloadImages(() => {
                    self.onImagesPreloaded?.(oldContainer, newContainer);
                    resolve();
                })
            })

        });

        this.load.on('ready', (transition) => {
        });
    }

    goTo(obj) {
        this.load.goTo(obj.url, obj.transition);
    }
}