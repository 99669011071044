import { module } from 'modujs';
import Splitting from 'splitting';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';

export default class extends module {
    constructor(m) {
        super(m);

        this.onFontsLoadedBind = this.onFontsLoaded.bind(this)
        this.$el = this.el
    }

    init() {
        this.bindEvents();

        if (window.isFontsLoaded) {
            this.onFontsLoaded()
        }
    }

    destroy() {
        super.destroy();
        this.unbindEvents();
    }

    onFontsLoaded() {
        this.split()

        gsap.from(this.$el, {
            scrollTrigger: {
                // markers: true,
                trigger: this.$el,
                start: 'top bottom',
                end: 'bottom top',
                onEnter: () => this.$el.classList.add('in-view'),
            }
        })
    }

    bindEvents() {
        window.addEventListener('fontsLoaded', this.onFontsLoadedBind)
    }

    unbindEvents() {
        window.removeEventListener('fontsLoaded', this.onFontsLoadedBind)
    }


    split() {
        const splitting = Splitting({ target: this.$el, by: 'chars' })
    }
}
