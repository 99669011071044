import { module } from 'modujs';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class extends module {
    constructor(m) {
        super(m);

        this.$el = this.el
        this.$elements = this.$el.querySelectorAll('a[href^="#"]');
        this.onClickBind = this.onClick.bind(this)
    }

    init() {
        console.log('anchor');
       this.bindEvents();
    }

    onClick(e) {
        console.log('on click');
        e.preventDefault();
        const target = e.target.getAttribute('href');

        console.log('target', target);
        this.call('deactivate', null, 'Nav')
        this.call('scrollTo', target, 'Scroll')
    }

    bindEvents() {
        this.$elements.forEach((elm) => {
            elm.addEventListener('click', this.onClickBind);
        })
    }

    unbindEvents() {
        this.$elements.forEach((elm) => {
            elm.removeEventListener('click', this.onClickBind);
        })
    }

    destroy() {
        this.unbindEvents();
    }

}
