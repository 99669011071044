function lazyLoadImage($img, url) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => {
            $img.src = url;
            $img.removeAttribute('data-preload');
            resolve();
        };
        img.onerror = reject;
    });
}

export function preloadImages(callback) {
    const $imagesToLoad = document.querySelectorAll('img[data-preload]');
    
    if (!$imagesToLoad.length) {
        callback?.()
        return
    }

    const promises = [];

    $imagesToLoad.forEach(($img) => {  
        const url = $img.dataset.preload;
        const promise = lazyLoadImage($img, url);
        promises.push(promise)
     });

    Promise.all(promises).then(callback?.());
}