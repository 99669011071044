import { module } from 'modujs';
import Lenis from 'lenis'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { html } from "../utils/environment";

import { debounce } from '../utils/debouce';

gsap.registerPlugin(ScrollTrigger);

export default class extends module {
    constructor(m) {
        super(m)

        //Binding
        this.rafBind = this.raf.bind(this)
        this.onFontsLoadedBind = this.onFontsLoaded.bind(this)
        this.onResizeBind = this.onResize.bind(this)
    }

    init() {
        this.initScroll()
        this.bindEvents()
        // gsap.ticker.add((time)=>{
        //     this.scroll.raf(time * 1000)
        // })

        gsap.ticker.lagSmoothing(0)
    }

    destroy() {
        this.scroll.destroy()
        super.destroy()
    }

    raf(time) {
        this.scroll.raf(time)
        requestAnimationFrame(this.rafBind)
    }

    initScroll() {
        this.scroll = new Lenis();


        this.scroll.on('scroll', (args) => {
            ScrollTrigger.update();

            html.style.setProperty('--velocity',  Math.abs(Math.round(args.velocity)));

            html.classList.remove('is-scrolling-up', 'is-scrolling-down');

            if(args.targetScroll) {
                html.classList.add('has-scroll');
            } else {
                html.classList.remove('has-scroll');
            }

            if(args.targetScroll > 220) {
                html.classList.add('has-scroll-safe');
            } else {
                html.classList.remove('has-scroll-safe');
            }

            if (args.direction === -1) {
                html.classList.add('is-scrolling-up');
            } else {
                html.classList.add('is-scrolling-down');
            }

            window.scrollDirection = args.direction
        });

        requestAnimationFrame(this.rafBind)
    }

    bindEvents() {
        window.addEventListener('fontsLoaded', this.onFontsLoadedBind)
        window.addEventListener('resizeEnd', this.onResizeBind)
    }

    onFontsLoaded() {
        this.scroll?.resize();
    }

    onResize() {
        this.scroll?.resize();
    }

    scrollTo(target, offset = 0) {
        this.start();

        const currentY = this.scroll?.y || 0;
        let targetY = 0;

        // If target is a string (selector), find the element
        if (typeof target === 'string') {
            const targetElement = document.querySelector(target);

            if (!targetElement) return;
            targetY = targetElement;
        } else if (typeof target === 'number') {
            // If target is a number, use it directly
            targetY = target + offset;
        }

        const distance = Math.abs(targetY - currentY);
        const pixelsPerSecond = 10000;
        const duration = distance / pixelsPerSecond;

        this.scroll?.scrollTo(targetY, { duration });
    }

    start() {
        this.scroll?.start();
    }

    pause() {
        this.scroll?.stop();
    }
}