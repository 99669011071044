import { module } from 'modujs';
import { gsap } from 'gsap';
import { currentBreakpoint } from '../utils/screen';
//  https://blog.olivierlarose.com/tutorials/parallax-scroll
export default class extends module {
    constructor(m) {
        super(m);

        this.$el = this.el
        this.$elements = this.$('element')
    }

    init() {


        const bp = ['xs', 'sm', 'md'];
        if (!window.isMobile && !bp.includes(currentBreakpoint)) {
            const x = window.scrollDirection === 1 ? 1 : -1;

                let speed = this.$el.getAttribute('data-pllx-speed') || 0.1;
                let start = this.$el.getAttribute('data-pllx-start') || 'top bottom';
                let end = this.$el.getAttribute('data-pllx-end') || 'bottom top';

                gsap.to(this.$el, {
                    yPercent: (100 * speed) * x,
                    ease: "sine.out",
                    scrollTrigger: {
                        trigger: this.$el,
                        start, 
                        end, 
                        // markers: true,
                        scrub: 1
                    }
                })
        }
    }
}
