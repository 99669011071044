import { module } from 'modujs';
import { gsap } from 'gsap';


export default class extends module {
    constructor(m) {
        super(m);


        this.$el = this.el
        this.$elements = this.$('element')

        this.onEnterBind = this.onEnter.bind(this)
    }

    init() {

        this.bindEvents();

        // gsap.to(this.$elements, {
        //     xPercent: -100 * (this.$elements.length - 1),
        //     ease: "sine.out",
        //     scrollTrigger: {
        //         trigger: this.$el,
        //         // snap: 1 / (this.$elements.length - 1),
        //         end: "+=" + this.$el.offsetWidth,
        //         scrub: 1,
        //         markers: true,
        //         pin: true
        //     }, // start the animation when ".box" enters the viewport (once)
        //   });
    }

    bindEvents() {
        this.$elements.forEach((elm) => {
            elm.addEventListener('mouseenter', this.onEnterBind);
        })
    }

    unbindEvents() {
        this.$elements.forEach((elm) => {
            elm.removeEventListener('mouseenter', this.onEnterBind);
        })
    }

    onEnter(e) {
        const target = e.currentTarget.querySelector('figure')
        const currentIndex = Array.from(this.$elements).indexOf(target)

        gsap.to(target, {
            ease: "sine.out",
            rotation: gsap.utils.random(-10, 10), 
            transformOrigin: "center center",
            x: gsap.utils.random(-20, 20), 
            y: gsap.utils.random(-20, 20), 
            duration: 0.3, 
        })
    }

    destroy() {
        super.destroy();
        this.unbindEvents();
    }
}
