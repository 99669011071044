const breakpoints = {
    'xs': 0,
    'sm': 640,
    'md': 768,
    'lg': 1024,
    'xl': 1280,
    '2xl': 1536
};

let currentBreakpoint = 'xs';

window.bp = function() {
    let result = 'xs';
    for (const [key, value] of Object.entries(breakpoints)) {
        if (window.matchMedia(`(min-width: ${value}px)`).matches) {
        result = key;
        }
    }
    return result;
};


window.addEventListener('resize', function() {
    currentBreakpoint = window.bp();
});

currentBreakpoint = window.bp();

export { breakpoints, currentBreakpoint };