import { module } from 'modujs';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class extends module {
    constructor(m) {
        super(m);

        this.$el = this.el
        this.$elements = this.$('element')
        this.onEnterBind = this.onEnter.bind(this)
        this.onLeaveBind = this.onLeave.bind(this)
    }

    init() {
        this.bindEvents();

    }

    bindEvents() {
        this.$elements.forEach((elm) => {
            elm.addEventListener('mouseenter', this.onEnterBind);
            elm.addEventListener('mouseleave', this.onLeaveBind);
        })
    }

    unbindEvents() {
        this.$elements.forEach((elm) => {
            elm.removeEventListener('mouseenter', this.onEnterBind);
            elm.addEventListener('mouseleave', this.onLeaveBind);

        })
    }

    onEnter(e) {
        e.currentTarget.classList.add('is-hovered');

        let video = e.currentTarget.querySelector('video');
        if (video) {
            video.play();
        }
    }

    onLeave(e) {
        e.currentTarget.classList.remove('is-hovered');

        let video = e.currentTarget.querySelector('video');
        if (video) {
            video.pause();
        }
    }

    destroy() {
        super.destroy();
        this.unbindEvents();
    }
}
