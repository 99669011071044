import { module } from 'modujs';
import { gsap } from 'gsap';
//  https://blog.olivierlarose.com/tutorials/parallax-scroll
export default class extends module {
    constructor(m) {
        super(m);

        this.$el = this.el
        this.$elements = this.$('element')
    }

    init() {
        const x = window.scrollDirection === 1 ? 1 : -1;
        
            const randomPostion = (e) => gsap.utils.random(-10, 10);
            gsap.to(this.$el, {
                y() {
                    return `+=${randomPostion(100)}`
                },
                x() {
                    return `+=${randomPostion(100)}`
                },
                rotate() {
                    return `+=${randomPostion(10)}`
                },
                yoyo: true,
                duration: 3,
                repeat: -1,
                ease: "sine.inOut",
                repeatRefresh: true,

            });
        }

       
   
}
