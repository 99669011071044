export async function fontsLoader(fonts, callback) {
    const fontFaceObservers = [];

    if (!fonts.length) return;

    fonts.forEach((font) => {
        const observer = loadFont(font.name, font.style, font.weight);
        fontFaceObservers.push(observer);
    });

    try {
        await Promise.all(fontFaceObservers)
        callback?.();
    } catch (err) {
        console.warn("Some fonts are not loaded", err);
    }
}

const loadFont = (fontName, fontStyle, fontWeight) => {
    return new Promise((resolve, reject) => {
        document.fonts.load(`${fontWeight} ${fontStyle} 12px "${fontName}"`)
            .then(() => {
                // console.info('Font loaded', fontName, fontStyle, fontWeight);
                resolve();
            })
            .catch(error => {
                console.info(`CSS font loading API error with ${fontName} ${fontStyle} ${fontWeight}`, error);
                resolve();
            });
    });
}
