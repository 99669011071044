import { module } from 'modujs';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class extends module {
    constructor(m) {
        super(m);

        this.$el = this.el
        this.$elements = this.$('element')
    }

    init() {

        this.$elements.forEach( (elm, i ) => {
            const line = elm.querySelector('.line');

            gsap.from(line, {
                scrollTrigger: {
                    // markers: true,
                    trigger: elm,
                    start: 'top bottom',
                    end: 'bottom top',
                    onEnter: () => elm.classList.add('in-view'),
                }
            })
        });


        // gsap.to(this.$elements, {
        //     xPercent: -100 * (this.$elements.length - 1),
        //     ease: "sine.out",
        //     scrollTrigger: {
        //         trigger: this.$el,
        //         snap: 1 / (this.$elements.length - 1),
        //         end: "+=" + this.$el.offsetWidth,
        //         scrub: 1,
        //         // markers: true,
        //         pin: true
        //     }, 
        //   });
    }
}
