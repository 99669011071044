import { module } from 'modujs';
import EmblaCarousel from 'embla-carousel'
import { currentBreakpoint } from '../utils/screen';

export default class extends module {
    constructor(m) {
        super(m);
        this.$el = this.el
    }

    init() {
        const bp = ['xs', 'sm', 'md'];

        if (window.isMobile && bp.includes(currentBreakpoint)) {
            const options = { loop: false, containScroll: false }
            const emblaApi = EmblaCarousel(this.$el, options)
        }
    }
}
