import { isMobile, isTouchDevice, html } from "./utils/environment";

export default async function () {


    window.isMobile = isMobile;
    if (window.isMobile) {
        html.classList.add('is-mobile')
    }

    window.isTouchDevice = isTouchDevice
    if (window.isMobile) {
        html.classList.add('is-touch-device')
    }
};