import { module } from 'modujs';
import { gsap } from 'gsap';
import Form from '../form';

//  https://blog.olivierlarose.com/tutorials/parallax-scroll
export default class extends module {
    constructor(m) {
        super(m);

        this.$el = this.el;
        this.$elements = this.el.elements;

        this.onSubmitBind = this.onSubmit.bind(this);
        this.onChangeBind = this.onChange.bind(this);
    }

    init() {
        this.bindEvents();
        }

        onChange(event) {

            if (event.target.value) {
                event.target.classList.add('has-value');
            } else {
                event.target.classList.remove('has-value');
            }
        }

        onSubmit(e) {
            e.preventDefault();

            Form.sendData(this.$el);
        }

        unbindEvents() {
            this.$el.removeventListener( "submit",  this.onSubmitBind);

            Array.from(this.$elements).forEach((elm) => {
                elm.removeEventListener('change', this.onChangeBind);
            })
          }
      
          bindEvents() {
              this.$el.addEventListener( "submit",  this.onSubmitBind);

              Array.from(this.$elements).forEach((elm) => {
                  elm.addEventListener('change', this.onChangeBind);
              })
          }

          destroy() {
            super.destroy()
            this.unbindEvents()
          }
   
}
